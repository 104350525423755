import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

import { Template, FloatingReadMore, BlogTransitionLayout, Meta } from 'common/layout';
import { SocialShare } from 'common/interaction/SocialShare';
import {
  BlogPageHeader,
  BlogPageBody,
  BlogPageInterestingArticles,
  BlogPageAuthorBlock,
} from 'modules/blog';

const BlogDetailPage: React.FC<BlogDetailPageProps> = ({ data, location }) => {
  const article = data.contentfulArticleWeb;
  const { title, subtitle, bannerImage, duration, content, author } = article;
  const bannerImageSrc = 'https:' + bannerImage.file.url;

  const pageBodyAnimation = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
        type: 'tween',
        bounce: 0,
      },
    },
  };

  const mainAnimation = (i: number) => ({
    initial: {
      opacity: 0,
      y: -20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.5,
        duration: 0.2,
        ease: 'easeIn',
        type: 'tween',
        bounce: 0,
      },
    },
  });

  return (
    <BlogTransitionLayout location={location}>
      <Template>
        <Meta title={title} image={bannerImageSrc} pathname={location.pathname} article />
        <FloatingReadMore animateScroll />
        <BlogPageHeader
          {...{
            title,
            subtitle,
            bannerImage,
            duration,
          }}
        />
        <AnimatePresence>
          <motion.div
            variants={pageBodyAnimation}
            key="blogPageBody"
          >
            <BlogPageBody content={{ content: {
              ...content,
            } }} />
          </motion.div>
          <motion.div
            style={{ margin: 'auto' }}
            variants={mainAnimation(2)}
            key="blogPageAuthor"
          >
            <BlogPageAuthorBlock {...{ author }} />
          </motion.div>
          <motion.div
            style={{ margin: 'auto' }}
            variants={mainAnimation(3)}
            key="blogPageSocialShare"
          >
            <SocialShare url={location.href} title={title} includeHeading />
          </motion.div>
          {article?.interestingArticle && (
            <motion.div
              style={{ margin: 'auto' }}
              variants={mainAnimation(4)}
              key="blogPageInterestingArticles"
            >
              <BlogPageInterestingArticles
                content={article?.interestingArticle}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Template>
    </BlogTransitionLayout>
  );
};

export default BlogDetailPage;

type BlogDetailPageProps = {
  data: i.BlogDetailArticleType;
  location: Location;
};

export const query = graphql`
  query BlogArticleWeb($id: String) {
    contentfulArticleWeb(id: {eq: $id}) {
      ...BlogPageInterestingArticlesFragment
      ...BlogPageAuthorFragment
      id
      title
      subtitle
      duration
      bannerImage {
        gatsbyImageData
        id
        file {
          url
        }
      }
      content {
        references {
          ...BlogPageBodyFragment
        }
        raw
      }
    }
  }
`;

export const blogPageBodyFragment = graphql`
  fragment BlogPageBodyFragment on Node {
    ... on ContentfulArticleWebImage {
      style
      reference
      __typename
      asset {
        gatsbyImageData
      }
      isParallax
      contentful_id
    }
    ... on ContentfulArticleWebVideo {
      __typename
      youtubeUrl
      name
      contentful_id
    }
  }
`;
